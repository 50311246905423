:root {
    --background: linear-gradient(
      135deg,
      var(--primaryDefault) -700%,
      transparent 150%
    );
    --margin: 0px;
    --width: 600px;
    --zoom: unset;
    --cursor: default;
    --border-radius: 0;
    --borderRadius: 0;
  }
  
  .bg-404 {
    background-image: url('../img/bg-not-found.png');
    background-repeat: none;
    background-size: cover;
  }
  
  .box {
    zoom: var(--zoom);
    align-items: center;
    aspect-ratio: 1/1;
    border-radius: var(--borderRadius);
    cursor: var(--cursor);
    height: var(--width);
    justify-content: center;
    width: var(--width);
  }
  
  .slide-in {
    animation: slide-in 2s cubic-bezier(0.25, 0.4, 0.45, 0.84) infinite
      alternate forwards;
  }
  
  @keyframes slide-in {
    0% {
      transform: translateZ(-1400px) translateY(20px);
      opacity: 0.4;
    }
    100% {
      transform: translateZ(0) translateY(0);
      opacity: 0.8;
    }
  }