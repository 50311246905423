@import '@sweetalert2/themes/dark/dark.css';

.header-mobile {
  @apply min-h-[80px] h-auto w-full flex items-center justify-between
    xl:w-auto xl:justify-normal;
}
.wrapper-nav-elements {
  @apply w-full !text-light-0 font-semibold flex flex-col
    xl:!flex xl:flex-row xl:justify-between xl:!h-full
    xl:w-full;
}
.wrapper-left-items {
  @apply flex flex-col py-2 xl:flex-row xl:items-center;
}
.wrapper-right-items {
  @apply flex items-center gap-4 max-xl:flex-col max-xl:items-start;
}
.nav-item-highlight {
  @apply w-min flex gap-2 items-center !text-light-0 mt-2 border-2 border-primary-600 bg-primary-600 leading-none p-2 mx-2 rounded-lg hover:bg-primary-600 hover:!text-light-0 transition
  xl:mt-0;
}
.height-calc-full {
  height: calc(100% - 118px);
}
.bp5-portal {
  z-index: 99999999999;
}

.input-name-edit {
  padding: 3px 6px;
}
.input-name-edit,
.input-name-edit::before,
.input-name-edit .bp5-editable-text-content {
  min-width: 240px !important;
}
.bp5-input {
  border-radius: 2px !important;
  border: 1px solid #5858583d !important;
}
