.fullscreen-loading {
  position: fixed;
  z-index: 999999999999;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: opacity 0.2s ease-out;
  background-color: #0f1116;
}

.content-center {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 10;
}

/* OPEN */
.content-center.actived {
  opacity: 1;
  transition: all 0.5s 0.2s cubic-bezier(0.99, 0.05, 0.6, 0.93);
}

/* CLOSED */
.content-center.closed {
  opacity: 0;
  transition: all 0.1s ease-out;
}

.load {
  display: block;
  position: absolute;
  width: 360px;
  height: 360px;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: #fff;
  animation: spin 2.2s linear infinite;
}

.load:before,
.load:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  border: 2px solid transparent;
}

.load:before {
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  border-top-color: #6e36fa;
  animation: spin-reverse 0.8s linear infinite;
}

.load:after {
  top: 12px;
  left: 12px;
  right: 12px;
  bottom: 12px;
  border-top-color: #a1fd41;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin-reverse {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.loader-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-out;
}
