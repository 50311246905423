@tailwind base;
@tailwind components;
@tailwind utilities;

/* Scrollbar */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 16px hsla(0, 0%, 17%, 1);
  border-radius: 5px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #4d4d4d;
  border-radius: 5px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #707070;
}

* {
  outline: none;
  box-sizing: border-box;
  list-style: none;
  @apply font-poppins;
}

html,
body,
#root {
  width: 100%;
  min-height: 100%;
  height: 100%;
}

.bp5-navbar.bp5-dark,
.bp5-dark .bp5-navbar {
  background: #0f1116;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2),
    0 1px 1px 0 rgba(17, 20, 24, 0.4) !important;
}

.bp5-intent-primary {
  background-color: #6d36fb !important;
}

.polotno-side-panel-tab:hover {
  background-color: rgba(109, 54, 251, 0.2) !important;
  color: #6d36fb !important;
}
.polotno-side-panel-tab:hover .bp4-icon {
  color: #6d36fb !important;
}
.polotno-side-panel-tab svg {
  display: inline-block;
}

button:focus,
a:focus {
  outline-color: transparent !important;
}
